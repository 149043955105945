import { HttpEventType } from "@angular/common/http";
import {
  CommonService,
  Design,
  DesignLayer,
  DesignService,
  DesignSide,
  DesignStatusEvent,
  DesignText
} from "@sata/cdg-api";
import { ConfigurationPageDto, ProductDto, ProductVariantDto } from "@smallstack/axios-api-client";
import { Logger } from "@smallstack/core-common";
import { NotificationService, TranslationStore } from "@smallstack/i18n-components";
import { BehaviorSubject, firstValueFrom, Observable, Subject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import * as _ from "underscore";
import { MAX_IMAGES_PER_SIDE } from "../app.constants";
import { GunCanvasConfig, GunCanvasImageEditingMode } from "./canvas/GunGame";
import { getNozzleTypes } from "./functions";
import { GoogleAnalyticsService } from "./ga.service";

export class DesignStore {
  get editable(): boolean {
    if (this.config && this.config.design) {
      return this.config.design.latestStatus.status === "created";
    }
    return false;
  }

  private get designId(): string {
    return this.config.design.id;
  }

  public get visibleSide(): "left" | "right" {
    return this.config.visibleSide;
  }

  public static REQUIRED_ASSETS = [
    // SATAJET 5000
    "/assets/overlays/satajet5000_anbauteile_schwarz_hvlp_links.png",
    "/assets/overlays/satajet5000_anbauteile_chrom_hvlp_links.png",
    "/assets/overlays/satajet5000_anbauteile_schwarz_rp_links.png",
    "/assets/overlays/satajet5000_anbauteile_chrom_rp_links.png",
    "/assets/overlays/satajet5000_pistole_links.png",
    "/assets/overlays/satajet5000_pistole_links-outline.png",
    "/assets/overlays/satajet5000_anbauteile_schwarz_hvlp_rechts.png",
    "/assets/overlays/satajet5000_anbauteile_chrom_hvlp_rechts.png",
    "/assets/overlays/satajet5000_anbauteile_schwarz_rp_rechts.png",
    "/assets/overlays/satajet5000_anbauteile_chrom_rp_rechts.png",
    "/assets/overlays/satajet5000_pistole_rechts.png",
    "/assets/overlays/satajet5000_pistole_rechts-outline.png",
    "/assets/overlays/satajet5000_digital-links.png",
    "/assets/overlays/satajet5000_digital-rechts_schwarz.png",
    "/assets/overlays/satajet5000_digital-rechts_chrom.png",
    "/assets/overlays/satajet5000_laserung_links_digital.png",
    "/assets/overlays/satajet5000_laserung_links_standard.png",
    "/assets/overlays/satajet5000_laserung_rechts_digital_hvlp.png",
    "/assets/overlays/satajet5000_laserung_rechts_digital_rp.png",
    "/assets/overlays/satajet5000_laserung_rechts_standard_hvlp.png",
    "/assets/overlays/satajet5000_laserung_rechts_standard_rp.png",

    // SATA JET5500
    "/assets/overlays/satajet5500_anbauteile_schwarz_hvlp_links.png",
    "/assets/overlays/satajet5500_anbauteile_chrom_hvlp_links.png",
    "/assets/overlays/satajet5500_anbauteile_schwarz_rp_links.png",
    "/assets/overlays/satajet5500_anbauteile_chrom_rp_links.png",
    "/assets/overlays/satajet5500_pistole_links.png",
    "/assets/overlays/satajet5500_pistole_links-outline.png",
    "/assets/overlays/satajet5500_anbauteile_schwarz_hvlp_rechts.png",
    "/assets/overlays/satajet5500_anbauteile_chrom_hvlp_rechts.png",
    "/assets/overlays/satajet5500_anbauteile_schwarz_rp_rechts.png",
    "/assets/overlays/satajet5500_anbauteile_chrom_rp_rechts.png",
    "/assets/overlays/satajet5500_pistole_rechts.png",
    "/assets/overlays/satajet5500_pistole_rechts-outline.png",
    "/assets/overlays/satajet5500_digital-links.png",
    "/assets/overlays/satajet5500_digital-rechts_schwarz.png",
    "/assets/overlays/satajet5500_digital-rechts_chrom.png",
    "/assets/overlays/satajet5500_laserung_links_digital.png",
    "/assets/overlays/satajet5500_laserung_links_standard.png",
    "/assets/overlays/satajet5500_laserung_rechts_digital_hvlp.png",
    "/assets/overlays/satajet5500_laserung_rechts_digital_rp.png",
    "/assets/overlays/satajet5500_laserung_rechts_standard_hvlp.png",
    "/assets/overlays/satajet5500_laserung_rechts_standard_rp.png",

    // UI
    "/assets/hud-assets/left-gun-button.png",
    "/assets/hud-assets/right-gun-button.png",

    "/assets/preview/preview_anbauteile_left_black_hvlp.png",
    "/assets/preview/preview_anbauteile_left_black_rp.png",
    "/assets/preview/preview_anbauteile_left_chrome_hvlp.png",
    "/assets/preview/preview_anbauteile_left_chrome_rp.png",
    "/assets/preview/preview_anbauteile_right_black_hvlp.png",
    "/assets/preview/preview_anbauteile_right_black_rp.png",
    "/assets/preview/preview_anbauteile_right_chrome_hvlp.png",
    "/assets/preview/preview_anbauteile_right_chrome_rp.png",

    "/assets/preview/preview_bg_mask_left.png",
    "/assets/preview/preview_bg_mask_right.png",
    "/assets/preview/preview_bg.jpg",

    "/assets/preview/preview_laserung_left_digital.png",
    "/assets/preview/preview_laserung_left_standard.png",
    "/assets/preview/preview_laserung_right_digital_hvlp.png",
    "/assets/preview/preview_laserung_right_digital_rp.png",
    "/assets/preview/preview_laserung_right_standard_hvlp.png",
    "/assets/preview/preview_laserung_right_standard_rp.png",

    "/assets/preview/preview_pistole_left.png",
    "/assets/preview/preview_pistole_right.png"
  ];

  public defaultConfig: GunCanvasConfig = {
    cameraOffset: { x: 0, y: 0 },
    design: undefined,
    editable: true,
    addOnsVisible: true,
    screenTooSmall: false,
    zoom: 1,
    showAnbauteile: true,
    showLasering: true,
    configuration: undefined,
    visibleSide: "left",
    loadingDesignStatus: undefined,
    networkActivity: undefined,
    networkProgress: 0,
    hiddenImageIds: [],
    editingMode: GunCanvasImageEditingMode.none,
    minZoom: 1,
    maxZoom: 2.5,
    offline: false
  };

  public config: GunCanvasConfig = this.defaultConfig;
  public product: ProductDto;

  // observables
  public configChanges$: Subject<GunCanvasConfig> = new BehaviorSubject<GunCanvasConfig>(this.config);
  public productChanges$: Subject<ProductDto> = new BehaviorSubject<ProductDto>(undefined);
  public productVariantChanges$: Observable<ProductVariantDto> = this.configChanges$.pipe(
    map((gcc) => gcc.design.productVariant),
    distinctUntilChanged((a, b) => _.isEqual(a, b))
  );
  public designChanges$: Observable<Design> = this.configChanges$.pipe(
    map((gcc) => gcc.design),
    distinctUntilChanged((a, b) => _.isEqual(a, b))
  );
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private assetsLoaded = false;

  private isListeningToSubmitUpdates = false;

  private colorSaveTimeout;

  private analyticsService: GoogleAnalyticsService = new GoogleAnalyticsService();

  constructor(
    private storeCode: string,
    private productTag: string,
    private navigate: (hash: string) => void,
    private designService: DesignService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private translationStore: TranslationStore
  ) {
    window.ononline = () => this.setOffline(false);
    window.onoffline = () => this.setOffline(true);
  }

  public async init() {
    const configurations: ConfigurationPageDto = await firstValueFrom(this.commonService.getConfigurations());
    const assetPrefixConf = configurations.elements.find((config) => config.key === "assets.prefix");
    if (assetPrefixConf === undefined)
      this.notificationService.showStandardErrorPopup(
        new Error("no assets.prefix configuration set, please go to the backoffice and add the related keys!")
      );
    this.config.configuration = {
      assetPrefix: assetPrefixConf.value
    };
    for (const configEntry of configurations.elements) {
      this.config.configuration[configEntry.key] = configEntry.value;
    }
    this.notifyDesign$();
  }

  public async addText(
    text: string,
    fontFace: DesignText.FontFaceEnum,
    fontStyle: DesignText.FontStyleEnum,
    color
  ): Promise<void> {
    const response = await this.designService
      .addText({
        designId: this.designId,
        addTextRequest: { side: this.config.visibleSide, text: { text, fontFace, fontStyle, color } }
      })
      .toPromise();
    this.config.design = response.design;
    this.selectLastLayer();
    this.config.editingMode = GunCanvasImageEditingMode.move;
    this.notifyDesign$();
  }

  public async updateTextFont(textId: string, text: Partial<DesignText>): Promise<void> {
    this.config.design = await this.designService
      .changeText({ designId: this.designId, textId, designText: text as any })
      .toPromise();
    this.notifyDesign$();
  }

  public changeZoom(newZoom: number): any {
    newZoom = Math.round(newZoom * 100) / 100;
    if (this.config.zoom !== newZoom) {
      this.config.zoom = boundary(this.config.minZoom, newZoom, this.config.maxZoom);
      this.notifyDesign$();
    }
  }

  public resetZoom() {
    this.config.zoom = this.config.minZoom;
    this.config.cameraOffset.x = 0;
    this.config.cameraOffset.y = 0;
    this.notifyDesign$();
  }

  public changeCameraOffset(x: number, y: number) {
    this.config.cameraOffset.x = x;
    this.config.cameraOffset.y = y;
    this.notifyDesign$();
  }

  public changeCamera(x: number, y: number) {
    this.config.cameraOffset.x = x;
    this.config.cameraOffset.y = y;
    this.notifyDesign$();
  }

  public setOffline(o: boolean) {
    if (this.config.offline !== o) {
      this.config.offline = o;
      this.notifyDesign$();
    }
  }

  public changeMode(newMode: GunCanvasImageEditingMode): any {
    if (this.config.editingMode !== newMode) {
      if (this.editable) {
        this.config.editingMode = newMode;
      } else {
        this.config.editingMode = GunCanvasImageEditingMode.none;
      }
      this.notifyDesign$();
    }
  }

  public getSelectedLayer() {
    return this.config.design.sides[this.config.visibleSide].layers.find(
      (img) => img.id === this.config.selectedLayerId
    );
  }

  public setSelectedLayer(layerId: string): any {
    if (this.config.selectedLayerId !== layerId) {
      const img = this.config.design.sides[this.config.visibleSide].layers.find((i) => i.id === layerId);
      if (img) {
        this.config.selectedLayerId = layerId;
      }
      if (this.config.editingMode === GunCanvasImageEditingMode.none) {
        this.config.editingMode = GunCanvasImageEditingMode.move;
      }
      this.notifyDesign$();
    } else {
      delete this.config.selectedLayerId;
      this.config.editingMode = GunCanvasImageEditingMode.none;
      this.notifyDesign$();
    }
  }

  public selectLastLayer() {
    // TODO: Is there a better way to get the id? - max
    this.setSelectedLayer(this.config.design.sides[this.visibleSide].layers.slice(-1)[0].id);
  }

  public async moveLayerToIndex(previousIndex: number, currentIndex: number) {
    if (previousIndex !== currentIndex) {
      // remove image from old index
      const i = this.config.design.sides[this.visibleSide].layers.splice(previousIndex, 1)[0];
      // add image at new index
      this.config.design.sides[this.visibleSide].layers.splice(currentIndex, 0, i);
      this.notifyDesign$();
      await this.updateDesignOnServer();
    }
  }
  public async changeBody(bodyType: string) {
    if (this.editable) {
      await this.updateProductVariantOptions({ VM010: bodyType });
      this.analyticsService.event("userClickAction", "changeBody", bodyType);
    }
  }

  public async updateNozzleSize(size: string): Promise<any> {
    await this.updateProductVariantOptions({ VM500: size });
    this.analyticsService.event("userClickAction", "changeNozzleSize", size);
  }

  public async updateLayerPosition(
    imageId: string,
    s: { x: number; y: number; width: number; height: number; angle: number }
  ) {
    const img = this.config.design.sides[this.config.visibleSide].layers.find((i) => i.id === imageId);
    img.x = s.x;
    img.y = s.y;
    img.width = s.width;
    img.height = s.height;
    img.angle = s.angle;
    this.notifyDesign$();
    await this.updateDesignOnServer();
  }

  public async deleteLayer(id: string) {
    if (this.config.selectedLayerId === id) {
      delete this.config.selectedLayerId;
      this.config.editingMode = GunCanvasImageEditingMode.none;
    }
    this.config.design.sides[this.config.visibleSide].layers = this.config.design.sides[
      this.config.visibleSide
    ].layers.filter((i) => i.id !== id);
    this.notifyDesign$();
    await this.updateDesignOnServer();
  }

  public windowResized(newWidth: number, newHeight: number) {
    let aSize = Math.min(newWidth - 400, newHeight - 200);
    if (this.isSmallScreen()) aSize = Math.min(newWidth, newHeight);
    const p = (this.config.zoom - this.config.minZoom) / (this.config.maxZoom - this.config.minZoom);
    this.config.minZoom = Math.min(1.5, Math.round(aSize / 75) / 10);
    this.config.maxZoom = this.config.minZoom * 4;
    const nz = (this.config.maxZoom - this.config.minZoom) * p + this.config.minZoom;
    this.config.zoom = boundary(this.config.minZoom, nz, this.config.maxZoom);
    this.notifyDesign$();
  }

  public colorChanged(color: string): void {
    if (this.config.design.color !== color) {
      this.config.design.color = color;
      this.notifyDesign$();
      if (this.colorSaveTimeout) {
        clearTimeout(this.colorSaveTimeout);
        delete this.colorSaveTimeout;
      }
      this.colorSaveTimeout = setTimeout(async () => {
        await this.updateDesignOnServer();
        delete this.colorSaveTimeout;
        this.analyticsService.event("userClickAction", "changeBodyColor", color);
      }, 500);
    }
  }

  public async updateProductCode(productCode: string): Promise<void> {
    this.setLoading(true);
    try {
      this.analyticsService.event("userClickAction", "changeProduct", productCode);
      this.config.design = await this.designService
        .updateProductCode({ designId: this.designId, updateProductCodeRequest: { productCode } })
        .toPromise();
      this.product = await this.designService.getDesignProduct({ designId: this.config.design.id }).toPromise();
      this.notifyDesign$();
      this.notifyProduct$();
    } catch (e) {
      this.notificationService.showStandardErrorPopup(e);
    } finally {
      this.setLoading(false);
    }
  }

  public toggleLaserung() {
    this.config.showLasering = !this.config.showLasering;
    this.analyticsService.event("userClickAction", "showLasering", "" + this.config.showLasering);
    this.notifyDesign$();
  }

  public toggleAnbauteile() {
    this.config.showAnbauteile = !this.config.showAnbauteile;
    this.analyticsService.event("userClickAction", "showExtensions", "" + this.config.showAnbauteile);
    this.notifyDesign$();
  }

  public setSmallScreen(isSmallScreen: boolean) {
    this.config.screenTooSmall = isSmallScreen;
    this.notifyDesign$();
  }

  public isSmallScreen(): boolean {
    return this.config.screenTooSmall;
  }

  public toggleImageVisibility(imageId: string) {
    if (this.config.selectedLayerId !== imageId) {
      if (this.config.hiddenImageIds.indexOf(imageId) !== -1) {
        this.config.hiddenImageIds = this.config.hiddenImageIds.filter((i) => i !== imageId);
      } else {
        this.config.hiddenImageIds.push(imageId);
      }
      this.notifyDesign$();
    }
  }

  public async changeKnobColor(color: "chrom" | "schwarz") {
    if (this.editable) {
      await this.updateProductVariantOptions({ VM200: color });
      this.analyticsService.event("userClickAction", "changeKnobColor", color);
    }
  }

  public async updateNozzleType(type: "HVLP" | "RP"): Promise<void> {
    this.analyticsService.event("userClickAction", "changeNozzleType", type);
    const nozzleSize =
      this.config &&
      this.config.design &&
      this.config.design.productVariant &&
      this.config.design.productVariant.options.VM500;
    const f = nozzleSize && nozzleSize.substring(0, nozzleSize.lastIndexOf(" "));
    let nt = f && type && `${f} ${type}`;
    const productNozzleTypes: string[] = getNozzleTypes(this.product);
    if (!nt || productNozzleTypes.indexOf(nt) === -1) {
      nt = productNozzleTypes.find((n) => n.indexOf(type) !== -1);
    }
    await this.updateProductVariantOptions({ VM500: nt });
  }

  public async uploadImage(p: { base64String: string; name: string; ext: string }) {
    Logger.info("DesignStore", "Uploading Image", p);
    this.config.networkActivity = this.translationStore.translateByKey(
      "sata.gundesigner.designstore.networkactivity.uploading"
    );
    this.config.networkProgress = 0.0000001;
    this.notifyDesign$();
    try {
      // const r = await this.gunDesignerService.uploadImage(this.designId, this.visibleSide, p.base64String, p.name, p.ext);
      const r = await this._uploadImage(p).toPromise();
      this.config.design = r.design;
      this.config.networkActivity = null;
      if (r.sizeWarning && !(await this.confirm("sizewarning"))) {
        this.config.design = await this.designService
          .deleteImage({ designId: this.designId, imageId: r.imageId })
          .toPromise();
      } else {
        if (!this.isLimitReached()) {
          this.selectLastLayer();
        } else {
          this.config.editingMode = GunCanvasImageEditingMode.none;
        }
      }
    } catch (e) {
      if (e.message !== "Operation aborted because the browser is offline.") {
        if (await this.confirm("uploadretry")) {
          this.config.networkActivity = null;
          this.notifyDesign$();
          await this.uploadImage(p);
          return;
        }
      }
    }
    this.config.networkActivity = null;
    this.notifyDesign$();
  }

  public isLimitReached() {
    let imagesPerSide = 0;
    for (const layer of this.config.design.sides[this.config.visibleSide].layers) {
      if (layer.image !== undefined) {
        imagesPerSide++;
      }
    }
    return imagesPerSide >= MAX_IMAGES_PER_SIDE;
  }

  public async checkDesign() {
    if (this.config.design.sides.left.layers.length === 0 || this.config.design.sides.right.layers.length === 0) {
      this.analyticsService.event("popup", "userForgotOneSide");
      if ((await this.confirm("checkbothsides")) === true) {
        await this.submitDesign();
      }
    } else {
      await this.submitDesign();
    }
  }

  public async submitDesign() {
    if (this.editable) {
      this.analyticsService.event("userClickAction", "addToCart");
      this.analyticsService.event(
        "userClickAction",
        "submittedDesignCount",
        "" + this.config.design.uploadedImages.length
      );
      this.config.editingMode = GunCanvasImageEditingMode.none;
      this.config.networkActivity = this.translationStore.translateByKey(
        "sata.gundesigner.designstore.networkactivity.submitting"
      ); // "Submitting ...";
      this.notifyDesign$();

      try {
        this.config.design = await this.designService.submitDesign({ designId: this.designId }).toPromise();
        this.listenToSubmitUpdates();
      } catch (e) {
        this.notifyUserAboutAnError(
          this.translationStore.translateByKey("sata.gundesigner.designstore.error.submitting")
        ); // "There was an error while the design was subitted. Please reload the page.", e);
      }
      this.config.networkActivity = null;
      this.notifyDesign$();
    }
  }

  public async resetDesign(): Promise<boolean> {
    if (this.editable) {
      if ((await this.confirm("resetdesign")) === true) {
        this.analyticsService.event("userClickAction", "resetDesign");

        await this.designService.deleteDesign({ designId: this.designId }).toPromise();
        this.config = this.defaultConfig;
        if ((await this.confirm("newdesign")) === true) {
          await this.loadDesign();
        } else {
          window.location.assign("https://sata.com");
        }
        return true;
      } else return false;
    }
  }

  public async deleteUploadedImage(imageId: string): Promise<void> {
    if (this.editable) {
      const b = await this.confirmDeleteUploadedImage(imageId);
      if (b) {
        try {
          this.config.design = await this.designService.deleteImage({ designId: this.designId, imageId }).toPromise();
        } catch (e) {
          this.notifyUserAboutAnError(
            this.translationStore.translateByKey("sata.gundesigner.designstore.error.deleting"),
            e
          );
        }
        this.notifyDesign$();
      }
    }
  }

  public setVisibleSide(side: "left" | "right") {
    this.analyticsService.event("userClickAction", "swapSides", side);
    if (this.config.visibleSide !== side) {
      this.config.visibleSide = side;
      this.notifyDesign$();
    }
  }

  public async confirmAddImageAgain(s3Key: string): Promise<boolean> {
    const alreadyAdded = !!this.config.design.sides[this.config.visibleSide].layers.find(
      (img) => img.image && img.image.s3Key.endsWith(s3Key)
    );
    if (alreadyAdded) {
      return await this.confirm("addimageagain");
    }
    return true;
  }

  public async addLibraryImage(libraryImage: string) {
    if (this.editable) {
      if (!(await this.confirmAddImageAgain(libraryImage))) {
        return;
      }

      this.config.networkActivity = this.translationStore.translateByKey(
        "sata.gundesigner.designstore.networkactivity.addingimage"
      ); // "Adding image...";
      this.notifyDesign$();
      try {
        this.config.design = await this.designService
          .addLibraryImage({
            designId: this.designId,
            addLibraryImageRequest: { side: this.visibleSide, libraryImage }
          })
          .toPromise();
      } catch (e) {
        this.notifyUserAboutAnError(
          this.translationStore.translateByKey("sata.gundesigner.designstore.error.addingimage"),
          e
        ); // "There was an error while adding the image."
      }
      this.config.networkActivity = null;
      this.notifyDesign$();
      this.selectLastLayer();
    }
  }

  public async addUploadedImage(imageId: string): Promise<void> {
    if (this.editable) {
      // addimageagain
      const uploadedImage = this.config.design.uploadedImages.find((img) => img.id === imageId);
      if (!(await this.confirmAddImageAgain(uploadedImage.s3Key))) {
        return;
      }
      this.config.networkActivity = this.translationStore.translateByKey(
        "sata.gundesigner.designstore.networkactivity.addingimage"
      ); // "Adding image...";
      this.notifyDesign$();
      try {
        this.config.design = await this.designService
          .addUploadImage({ designId: this.designId, addUploadImageRequest: { side: this.visibleSide, imageId } })
          .toPromise();
      } catch (e) {
        this.notifyUserAboutAnError(
          this.translationStore.translateByKey("sata.gundesigner.designstore.error.addingimage"),
          e
        ); // "There was an error while adding the image."
      }
      this.config.networkActivity = null;
      this.notifyDesign$();
      this.selectLastLayer();
    }
  }

  public async preloadAssets(): Promise<void> {
    if (!this.assetsLoaded) {
      const assetPrefix = this.config.configuration.assetPrefix;
      const additionalAssets: string[] = [
        // "https://fonts.googleapis.com/icon?family=Material+Icons",
        // "https://fonts.googleapis.com/css?family=Roboto:300,400,500"
        // "https://cdn.smallstack.io/images/flags/flag-icon-css/css/flag-icon.min.css"
      ];
      const additionalAssetPromises = additionalAssets.map(async (n) => {
        const r = await fetch(n);
        if (!r.ok) {
          return Promise.reject("failed");
        }
      });

      await Promise.all(
        DesignStore.REQUIRED_ASSETS.map((n) => `${assetPrefix}${n}`)
          .map((url) => {
            return new Promise<void>((resolve, reject) => {
              const i = new Image();
              i.onerror = () => {
                Logger.error("DesignStore", `Could not load ${url}`);
                reject();
              };
              i.onload = (ev: ProgressEvent) => {
                resolve();
              };
              i.src = url;
            });
          })
          .concat(additionalAssetPromises)
      );
      this.assetsLoaded = true;
    }
  }

  public async loadDesign(id?: string) {
    this.config.loadingDesignStatus = "loading";
    this.notifyDesign$();

    try {
      try {
        await this.preloadAssets();
      } catch (er) {
        this.config.loadingDesignStatus = "network-error";
        this.notifyDesign$();
        return;
      }
      if (id) {
        Logger.info("DesignStore", `Loading design ${id}`);

        try {
          this.config.design = await this.designService.getDesign({ designId: id }).toPromise();
          this.product = await this.designService.getDesignProduct({ designId: this.config.design.id }).toPromise();
          Logger.info("DesignStore", `Design loaded. Status: ${this.config.design.status}`);
          this.config.loadingDesignStatus = "ok";
          if (
            !this.forwardToMagento() &&
            (this.config.design.latestStatus.status === DesignStatusEvent.StatusEnum.Submitting ||
              this.config.design.latestStatus.status === DesignStatusEvent.StatusEnum.SubmittingMagentoStarted)
          ) {
            this.listenToSubmitUpdates();
          }
        } catch (e) {
          if (e.status === 404) {
            this.config.loadingDesignStatus = "not-found";
          } else {
            this.config.loadingDesignStatus = "load-error";
          }
          Logger.error("DesignerComponent", "Error while loading design!", e);
        }
      } else {
        Logger.info("DesignStore", "Creating new design");
        this.config.design = await this.designService
          .createDesign({ createDesignRequest: { storeCode: this.storeCode, productTag: this.productTag } })
          .toPromise();
        this.product = await this.designService.getDesignProduct({ designId: this.config.design.id }).toPromise();
        this.navigate(this.config.design.id);
        // this.config.loadingDesignStatus = "ok";
      }
      this.config.zoom = this.config.minZoom;
      this.notifyDesign$();
      this.notifyProduct$();
    } catch (e) {
      this.config.loadingDesignStatus = "load-error";
      this.notifyDesign$();
      Logger.error("DesignerComponent", "Error while loading design (load-error)!", e);
    }
  }

  public getVariantForOptions(productVariants: ProductVariantDto[], options: any): ProductVariantDto {
    const variants: ProductVariantDto[] = productVariants.filter((variant: ProductVariantDto) => {
      for (const optionKey in options) {
        if (variant.options[optionKey] !== options[optionKey]) return false;
      }
      return true;
    });
    if (variants.length === 0)
      throw new Error("no variant found for given options, options are: " + JSON.stringify(options));
    if (variants.length > 1)
      throw new Error(
        `more than one variant found for given options, options are: ${JSON.stringify(
          options
        )}, variants are: ${JSON.stringify(variants)} (${variants.length}/${productVariants.length})`
      );
    return variants[0];
  }

  private _uploadImage(p: {
    base64String: string;
    name: string;
    ext: string;
  }): Observable<{ imageId: string; design: Design; sizeWarning: boolean }> {
    return this.designService
      .uploadImage(
        {
          designId: this.designId,
          uploadImageRequest: { image: p.base64String, extension: p.ext, side: this.config.visibleSide, name: p.name }
        },
        "events",
        true
      )
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.config.networkProgress = Math.max(0.0000001, event.loaded / event.total);
              this.notifyDesign$();
              break;
            case HttpEventType.Response:
              this.config.networkProgress = 0;
              return event.body;
          }
        })
      );
  }

  private async confirm(key: string): Promise<boolean> {
    const title = this.translationStore.translateByKey(`sata.gundesigner.designstore.${key}.title`, {
      showMissingKey: true
    });
    const message = this.translationStore.translateByKey(`sata.gundesigner.designstore.${key}.message`, {
      showMissingKey: true
    });
    const yes = this.translationStore.translateByKey(`sata.gundesigner.designstore.${key}.yes`, {
      showMissingKey: true
    });
    const no = this.translationStore.translateByKey(`sata.gundesigner.designstore.${key}.no`, { showMissingKey: true });
    return this.confirmDialog(title, message, yes, no);
  }

  private async confirmDialog(title: string, message: string, yes: string, no: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.notificationService.popup.confirmation(
        title,
        message,
        [
          { label: no, value: "no", classes: "mat-flat-button mat-primary" },
          { label: yes, value: "yes", classes: "mat-flat-button mat-accent ml-4" }
        ],
        async (a) => {
          resolve(a === "yes");
        }
      );
    });
  }

  private async confirmDeleteUploadedImage(imageId: string): Promise<boolean> {
    const hasImageAsLayer = !!Object.keys(this.config.design.sides).find((side) => {
      const s: DesignSide = this.config.design.sides[side];
      return !!s.layers.find((i) => !i.image || i.image.s3Key.endsWith(imageId));
    });
    if (hasImageAsLayer) {
      return this.confirm("deleteuploadconfirm");
    } else {
      return true;
    }
  }

  private async updateProductVariantOptions(newOptions: { [key: string]: string }) {
    this.setLoading(true);
    try {
      const options = JSON.parse(JSON.stringify(this.config.design.productVariant.options));
      Object.keys(newOptions).forEach((k) => (options[k] = newOptions[k]));
      // const options: any = _.extend(_.clone(this.config.design.productVariant.options), newOptions);
      this.config.design.productVariant = this.getVariantForOptions(this.product.variants, options);
      this.config.design = await this.designService
        .updateDesignVariant({ designId: this.config.design.id, gunDesignerVariant: options })
        .toPromise();
      this.notifyDesign$();
    } catch (e) {
      this.notificationService.showStandardErrorPopup(e);
      throw e;
    } finally {
      this.setLoading(false);
    }
  }

  private async updateDesignOnServer() {
    this.setLoading(true);
    Logger.info("design.component", "updateDesignOnServer");
    for (const s of ["left", "right"]) {
      const side: DesignSide = this.config.design.sides[s];
      side.layers.forEach((i: DesignLayer) =>
        Logger.info("design.component", `Layer: ${i.x}, ${i.y} w:${i.width} h:${i.height} angle:${i.angle}`)
      );
    }
    this.config.design = await this.designService
      .updateDesign({ designId: this.config.design.id, design: this.config.design })
      .toPromise();
    this.notifyDesign$();
    this.setLoading(false);
  }

  private forwardToMagento(): boolean {
    if (this.config.design.magentoForwardUrl) {
      // if (window.parent) {
      //     window.parent.location.assign(this.config.design.magentoForwardUrl);
      // } else
      window.location.assign(this.config.design.magentoForwardUrl);
      return true;
    }
  }

  private notifyUserAboutAnError(message: string, e?: Error): void {
    this.notificationService.showStandardErrorPopup(e, message);
    if (e) {
      Logger.error("DesignerComponent", "Error", e);
    }
  }

  private notifyDesign$() {
    this.config.editable =
      this.config.design && this.config.design.latestStatus.status === DesignStatusEvent.StatusEnum.Created;
    this.configChanges$.next(this.config);
  }

  private notifyProduct$() {
    this.productChanges$.next(this.product);
  }

  private setLoading(isLoading: boolean) {
    this.loading$.next(isLoading);
  }

  private listenToSubmitUpdates() {
    if (!this.isListeningToSubmitUpdates) {
      this.isListeningToSubmitUpdates = true;
      setTimeout(async () => {
        try {
          this.config.design = await this.designService.getDesign({ designId: this.designId }).toPromise();
          this.isListeningToSubmitUpdates = false;
          this.notifyDesign$();
          Logger.info(
            "designer.component",
            `Got updates - status: ${this.config.design.status} forward url:${this.config.design.magentoForwardUrl}`
          );
          if (!this.forwardToMagento()) {
            this.listenToSubmitUpdates();
          }
        } catch (e) {
          this.isListeningToSubmitUpdates = false;
          this.notifyUserAboutAnError(
            this.translationStore.translateByKey("sata.gundesigner.designstore.networkactivity.submitting"),
            e
          );
        }
      }, 1000);
    }
  }
}

function boundary(min: number, n: number, max: number): number {
  if (n > max) {
    return max;
  } else if (n < min) {
    return min;
  } else {
    return n;
  }
}
