import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { Logger } from "@smallstack/core-common";
import "hammerjs";
import { AppModule } from "./app/app.module";
import { smallstackApiUrl } from "./app/app.subscriptions";
import { localeFix } from "./app/designer/functions";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

smallstackApiUrl.next(environment.smallstackApiUrl);
Logger.info("Main", `Using Endpoint: ${smallstackApiUrl.value}`);

localeFix();

// tslint:disable-next-line: no-console
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
