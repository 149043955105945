import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { Logger } from "@smallstack/core-common";
import { TranslationStore } from "@smallstack/i18n-components";
import { Subscription } from "rxjs";
import { MAX_IMAGE_SIZE } from "../app.constants";
import { GunCanvasImageEditingMode } from "./canvas/GunGame";
import { DesignStore } from "./DesignStore";
import { GoogleAnalyticsService } from "./ga.service";
import { ImagesAndTextComponent } from "./images-and-text.component";

@Component({
  selector: "image-library",
  templateUrl: "./image-library.component.html",
  styleUrls: ["./image-library.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageLibraryComponent implements OnInit, OnDestroy {
  public libraryImages: Array<{ name: string; backgroundImage: any }>;
  public uploadedImages: Array<{ id: string; backgroundImage: any }>;

  @Input()
  public store: DesignStore;
  public file;

  public disabled: boolean;
  public limitReached: boolean;

  private subscription: Subscription;
  private assetPrefix: string;

  constructor(
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public designStore: DesignStore,
    private snackBar: MatSnackBar,
    private imagesAndTextComponent: ImagesAndTextComponent,
    private analyticsService: GoogleAnalyticsService,
    private translationStore: TranslationStore
  ) {
    this.store = designStore;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }

  public ngOnInit(): void {
    this.subscription = this.store.configChanges$.subscribe((cfg) => {
      const a = cfg && cfg.configuration && cfg.configuration.assetPrefix;
      this.disabled = cfg.editingMode !== GunCanvasImageEditingMode.none;
      if (a) {
        this.assetPrefix = a;

        this.libraryImages = this.store.config.configuration?.templates?.split(",").map((iName) => {
          const url =
            this.assetPrefix && `${this.assetPrefix}/assets/library-images/${this.getPreviewImageName(iName)}`;
          const sanitizedUrl = this.domSanitizer.bypassSecurityTrustStyle(
            `${this.disabled ? "linear-gradient(white, black)," : ""} url(${url})`
          );
          return {
            name: iName,
            backgroundImage: sanitizedUrl
          };
        });

        this.uploadedImages = cfg.design.uploadedImages.map((img) => {
          const sanitizedUrl = this.domSanitizer.bypassSecurityTrustStyle(`url(${img.previewUrl || img.url})`);
          return {
            id: img.id,
            backgroundImage: sanitizedUrl
          };
        });

        // this needs to change when merging with the text fields
        this.limitReached = this.store.isLimitReached();

        this.cdr.detectChanges();
      }
    });
  }

  public async deleteUploadedImage(event, imageId: string) {
    event.stopPropagation();
    if (!this.disabled) await this.store.deleteUploadedImage(imageId);
  }

  public async addUploadedImage(imageId: string) {
    if (this.disabled)
      this.notifyUserAboutAnError(
        this.translationStore.translateByKey("sata.gundesigner.imagelibrarycomponent.finisheditingfirst")
      );
    else if (this.limitReached)
      this.notifyUserAboutAnError(
        this.translationStore.translateByKey("sata.gundesigner.imagelibrarycomponent.limitreached")
      );
    else await this.store.addUploadedImage(imageId);
  }

  public async addLibraryImage(libraryImage: string) {
    if (this.disabled)
      this.notifyUserAboutAnError(
        this.translationStore.translateByKey("sata.gundesigner.imagelibrarycomponent.finisheditingfirst")
      );
    else if (this.limitReached)
      this.notifyUserAboutAnError(
        this.translationStore.translateByKey("sata.gundesigner.imagelibrarycomponent.limitreached")
      );
    else await this.store.addLibraryImage(libraryImage);
  }

  public hideImageLibrary() {
    this.imagesAndTextComponent.hideImageLibrary();
  }

  public handleFileSelect(evt) {
    this.analyticsService.event("userClickAction", "uploadImage");
    const files = evt.target.files;
    const file: File = files[0];
    if (!file) throw new Error("No files uploaded!");
    const ar: string[] = file.name.split(".");
    const ext = ar.pop().toLowerCase();
    const name = ar.join(".").toLowerCase();

    if (files && file) {
      if (!(ext === "png" || ext === "jpg" || ext === "jpeg" || ext === "gif")) {
        this.notifyUserAboutAnError(
          this.translationStore.translateByKey("sata.gundesigner.imagelibrarycomponent.imageformatlimit")
        );
      } else {
        const reader: FileReader = new FileReader();
        reader.onload = async (readerEvt: any) => {
          if (readerEvt.loaded === readerEvt.total) {
            if (readerEvt.total > MAX_IMAGE_SIZE) {
              this.notifyUserAboutAnError(
                this.translationStore.translateByKey("sata.gundesigner.imagelibrarycomponent.imageformatlimit")
              );
            } else {
              const dataUrl = readerEvt.target.result;
              const base64String = dataUrl.split(",")[1];
              void this.store.uploadImage({ base64String, ext, name: `${name}.${ext}` }).then(() => {
                if (this.limitReached)
                  this.notifyUserAboutAnError(
                    this.translationStore.translateByKey("sata.gundesigner.imagelibrarycomponent.limitreached")
                  );
              });
            }
          }
        };
        reader.readAsDataURL(file);
      }
      evt.target.value = "";
    }
  }

  private notifyUserAboutAnError(message: string, e?: Error): void {
    const config = new MatSnackBarConfig();
    config.duration = 6000;
    this.snackBar.open(message, undefined, config);
    if (e) {
      Logger.error("DesignerComponent", "Error while loading design!", e);
    }
  }

  private getPreviewImageName(libraryName: string) {
    const ar: string[] = libraryName.split(".");
    const ext = ar.pop();
    const name = ar.join(".");
    return `${name}_preview.${ext}`;
  }
}
