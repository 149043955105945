import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { DesignStatusEvent } from "@sata/cdg-api";
import { PriceZoneDto, ProductDto, ProductVariantDto } from "@smallstack/axios-api-client";
import { LocaleService } from "@smallstack/i18n-components";
import { Subscription } from "rxjs";
import { DesignStore } from "./DesignStore";
import { isHVLP } from "./functions";
import { GoogleAnalyticsService } from "./ga.service";

@Component({
  selector: "wizard-component",
  templateUrl: "./wizard.component.html",
  styleUrls: ["./wizard.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardComponent implements OnInit, OnDestroy {
  @Input()
  public store: DesignStore;

  public editable: boolean;

  public nozzleType: "HVLP" | "RP";
  public locale: string;
  public color: string;
  public knobColor = "chrom";
  public bodyType: string;
  public vm010: string;
  public nozzleSize: string;

  // PIM stuff
  public product: ProductDto;
  public productVariant: ProductVariantDto[];
  public storeCode: string;
  public priceZone: PriceZoneDto;
  public productCode: string;

  @ViewChild("controlbar") public controlbar: ElementRef;
  private anbauteileVisible: boolean;
  private subscription: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private analyticsService: GoogleAnalyticsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public popupStore: DesignStore,
    private localeService: LocaleService
  ) {
    this.store = popupStore;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }
  public ngOnInit() {
    this.subscription.add(
      this.store.configChanges$.subscribe((cfg) => {
        this.color = cfg && cfg.design && cfg.design.color;
        this.editable = cfg && cfg.design && cfg.design.latestStatus.status === DesignStatusEvent.StatusEnum.Created;
        this.nozzleType = cfg && cfg.design && isHVLP(cfg.design) ? "HVLP" : "RP";
        this.nozzleSize = cfg && cfg.design && cfg.design.productVariant && cfg.design.productVariant.options.VM500;
        this.bodyType = cfg && cfg.design && cfg.design.productVariant && cfg.design.productVariant.options.VM010;
        this.knobColor = cfg && cfg.design && cfg.design.productVariant && cfg.design.productVariant.options.VM200;
        this.anbauteileVisible = cfg && cfg.showAnbauteile;
        this.storeCode = cfg && cfg.design && cfg.design.magentoStoreCode;
        this.productCode = cfg && cfg.design && cfg.design.productCode;
        this.productVariant = cfg && cfg.design && (cfg.design.productVariant as any);
        this.cdr.detectChanges();
      })
    );
    this.subscription.add(
      this.store.productChanges$.subscribe((product) => {
        this.product = product;
      })
    );
    this.localeService.currentLocale$.subscribe((locale: string) => (this.locale = locale));
  }

  public selectedTabChange(e: MatTabChangeEvent) {
    if (!this.anbauteileVisible && e.index === 0) {
      this.store.toggleAnbauteile();
    }
    if (e.index === 0) this.analyticsService.event("userClickAction", "changeTab", "basicSettings");
    if (e.index === 1) this.analyticsService.event("userClickAction", "changeTab", "design");
  }
}
