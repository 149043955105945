import { Design, DesignLayer } from "@sata/cdg-api";
import { ProductDto, ProductOptionDto } from "@smallstack/axios-api-client";

export function isHVLP(d: Design): boolean {
  return d.productVariant.options.VM500.indexOf("HVLP") !== -1;
}

export function getFontSize(layer: DesignLayer, width?: number): number {
  if (layer.text || width) {
    return (width || layer.width) / 3;
  } else {
    return 10;
  }
}

export function localeFix() {
  const currentLocale = localStorage.getItem("language");
  if (currentLocale !== null && currentLocale.indexOf("_") === -1) {
    localStorage.removeItem("language");
  }
}

export function getNozzleTypes(product: ProductDto): string[] {
  if (product) {
    const VM500Option: ProductOptionDto = product.options.find((po) => po.key === "VM500");
    if (!VM500Option) throw new Error("No VM500 option found on product " + product.id);
    return VM500Option.values.map((pov) => pov.value);
  }
}
