<div cdkDropList (cdkDropListDropped)="drop($event)">
  <mat-divider></mat-divider>
  <div *ngFor="let l of layers">
    <div
      cdkDrag
      [cdkDragDisabled]="!editable"
      [ngClass]="{ 'layer-element': true, movable: true, 'layer-element-selected': l.selected }"
      (click)="setSelectedLayer(l)"
    >
      <div class="layer-group">
        <mat-icon class="layer-icon drag" *ngIf="editable" cdkDragHandle>drag_indicator</mat-icon>
        <div class="layer-image" [style.background-image]="l.imageUrl" *ngIf="l.imageUrl"></div>
        <span class="layer-image" *ngIf="!l.imageUrl">
          <mat-icon class="layer-icon text-field">font_download</mat-icon>
        </span>
        <span class="layer-label">{{ l.label }}</span>
      </div>
      <div class="layer-group">
        <button mat-icon-button *ngIf="l.deletable" (click)="deleteLayer(l)">
          <mat-icon class="layer-icon delete">delete_outline</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleVisible(l)">
          <mat-icon class="layer-icon visibility" *ngIf="l.visible">visibility</mat-icon>
          <mat-icon class="layer-icon visibility" *ngIf="!l.visible">visibility_off</mat-icon>
        </button>
      </div>
    </div>
    <text-detail
      class="inline-text-editor"
      *ngIf="isInlineTextFieldEditorShown && l.selected"
      [store]="store"
    ></text-detail>
    <mat-divider></mat-divider>
  </div>
</div>
