<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <h1 mat-dialog-title style="margin-bottom: 0px; text-align: center">
    <smallstack-i18n data="@@sata.gundesigner.locked.title"></smallstack-i18n>
  </h1>
  <mat-icon style="width: 100px; height: 100px; font-size: 100px; color: rgb(27, 196, 27)"
    >check_circle_outline
  </mat-icon>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
    <smallstack-i18n data="@@sata.gundesigner.locked.text"></smallstack-i18n>
  </div>
</div>
