// tslint:disable: no-string-literal
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class GoogleAnalyticsService {
  public ga(...args) {
    if (window["ga"] && environment.name === "production") {
      window["ga"].apply(null, args);
    }
  }

  public pageView(url: string) {
    this.ga("send", "pageview", url);
  }

  public event(category: string, action: string, label?: string, value?: string) {
    this.ga("send", "event", category, action, label, value);
  }
}
