<div fxLayout="column" fxLayoutGap="10px">
  <price-box [store]="data.store"></price-box>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <a
      style="width: 100%"
      mat-stroked-button
      color="accent"
      href="mailto:?subject=SATA Custom Design Gun&body=Design URL: {{ designUrl }}"
    >
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.maildesign"></smallstack-i18n>
    </a>
  </div>
</div>
