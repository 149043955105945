/**
 * SATA Gun Designer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DesignStatusEvent { 
    date: number;
    status: DesignStatusEvent.StatusEnum;
    text: string;
}
export namespace DesignStatusEvent {
    export type StatusEnum = 'created' | 'submitting' | 'submittingMagentoStarted' | 'submittingMagentoDone' | 'submitted' | 'approved' | 'rejected' | 'magentoProcessing' | 'magentoCanceled' | 'magentoHolded' | 'anonymized';
    export const StatusEnum = {
        Created: 'created' as StatusEnum,
        Submitting: 'submitting' as StatusEnum,
        SubmittingMagentoStarted: 'submittingMagentoStarted' as StatusEnum,
        SubmittingMagentoDone: 'submittingMagentoDone' as StatusEnum,
        Submitted: 'submitted' as StatusEnum,
        Approved: 'approved' as StatusEnum,
        Rejected: 'rejected' as StatusEnum,
        MagentoProcessing: 'magentoProcessing' as StatusEnum,
        MagentoCanceled: 'magentoCanceled' as StatusEnum,
        MagentoHolded: 'magentoHolded' as StatusEnum,
        Anonymized: 'anonymized' as StatusEnum
    };
}


