<div [ngClass]="store.isSmallScreen() ? 'mobile-lib' : ''" class="image-library" fxLayout="column" fxLayoutGap="24px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon>cloud_upload</mat-icon>&nbsp;
      <span class="mat-subheading-2">
        <smallstack-i18n data="@@sata.gundesigner.wizard.images"></smallstack-i18n>
      </span>
    </div>
    <a (click)="hideImageLibrary()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <div class="mt-32" style="min-height: 125px; overflow-x: auto" fxLayout="row" fxLayoutAlign="start">
    <div
      *ngFor="let libraryImage of libraryImages"
      (click)="addLibraryImage(libraryImage.name)"
      class="design-box"
      [style.background-image]="libraryImage.backgroundImage"
      style="background-blend-mode: saturation; background-size: cover; background-color: initial"
    >
    </div>
  </div>
  <div class="boxes mt-32" fxLayout="row" fxLayoutAlign="start" style="overflow-x: auto">
    <div
      *ngIf="!disabled"
      class="design-box"
      (click)="fileInputLeft.click()"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon class="add-icon">add_circle</mat-icon>
      <input #fileInputLeft type="file" (change)="handleFileSelect($event)" style="display: none" />
    </div>
    <div
      id="custom-image"
      *ngFor="let uploadedImage of uploadedImages.slice().reverse()"
      (click)="addUploadedImage(uploadedImage.id)"
      class="design-box"
      [style.background-image]="uploadedImage.backgroundImage"
      style="background-blend-mode: saturation; background-size: cover; background-color: initial"
      fxLayout="column"
      fxLayoutAlign="start end"
    >
      <a (click)="deleteUploadedImage($event, uploadedImage.id)">
        <mat-icon class="remove-icon">remove_circle</mat-icon>
      </a>
    </div>
  </div>
</div>
