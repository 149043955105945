/**
 * SATA Gun Designer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DesignText { 
    text: string;
    fontFace: DesignText.FontFaceEnum;
    fontStyle: DesignText.FontStyleEnum;
    color: string;
}
export namespace DesignText {
    export type FontFaceEnum = 'Arimo' | 'Lato' | 'Montserrat' | 'Muli' | 'OpenSans' | 'Poppins' | 'PtSansWeb' | 'Roboto' | 'RobotoCondensed' | 'Titilliumweb' | 'Ubuntu';
    export const FontFaceEnum = {
        Arimo: 'Arimo' as FontFaceEnum,
        Lato: 'Lato' as FontFaceEnum,
        Montserrat: 'Montserrat' as FontFaceEnum,
        Muli: 'Muli' as FontFaceEnum,
        OpenSans: 'OpenSans' as FontFaceEnum,
        Poppins: 'Poppins' as FontFaceEnum,
        PtSansWeb: 'PtSansWeb' as FontFaceEnum,
        Roboto: 'Roboto' as FontFaceEnum,
        RobotoCondensed: 'RobotoCondensed' as FontFaceEnum,
        Titilliumweb: 'Titilliumweb' as FontFaceEnum,
        Ubuntu: 'Ubuntu' as FontFaceEnum
    };
    export type FontStyleEnum = 'Regular' | 'RegularItalic' | 'Bold' | 'BoldItalic';
    export const FontStyleEnum = {
        Regular: 'Regular' as FontStyleEnum,
        RegularItalic: 'RegularItalic' as FontStyleEnum,
        Bold: 'Bold' as FontStyleEnum,
        BoldItalic: 'BoldItalic' as FontStyleEnum
    };
}


