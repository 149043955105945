<div
  fxLayout="column"
  [ngClass]="{
    'textfield-editor': isEditingNewText,
    'inline-textfield-editor': !isEditingNewText,
    'mobile-lib': store.isSmallScreen()
  }"
>
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="isEditingNewText">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon>font_download</mat-icon>&nbsp;
      <span class="mat-subheading-2">
        <smallstack-i18n data="@@sata.gundesigner.wizard.textfields"></smallstack-i18n>
      </span>
    </div>
    <a (click)="hideTextFieldEditor()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <mat-form-field fxFlex>
      <mat-label>
        <smallstack-i18n data="@@sata.gundesigner.textfield.text"></smallstack-i18n>
      </mat-label>
      <input matInput [(ngModel)]="text" [disabled]="!isEditingNewText && !editStoredText" />
      <mat-icon matSuffix *ngIf="!isEditingNewText && !editStoredText" (click)="editStoredText = true"
        >mode_edit
      </mat-icon>
    </mat-form-field>
    <button mat-stroked-button (click)="textChanged()" *ngIf="editStoredText" fxFlex="100px">
      <smallstack-i18n data="@@common.save"></smallstack-i18n>
    </button>
    <input
      fxFlex="50px"
      class="colorpicker"
      readonly="true"
      [(colorPicker)]="color"
      [cpOKButton]="true"
      [cpOKButtonClass]="'mat-flat-button'"
      [cpCancelButton]="true"
      [cpCancelButtonClass]="'mat-flat-button'"
      [cpPosition]="'left'"
      [cpAlphaChannel]="'disabled'"
      [style.background]="color"
      [cpCloseClickOutside]="false"
      (colorPickerChange)="changeColor($event)"
      (colorPickerSelect)="saveColor($event)"
    />
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <mat-form-field fxFlex="50">
      <mat-label>
        <smallstack-i18n data="@@sata.gundesigner.textfield.fontface"></smallstack-i18n>
      </mat-label>
      <mat-select [ngModel]="fontFace" [style.font-family]="fontFace">
        <mat-option *ngFor="let ff of fontFaces" [value]="ff.value" (click)="updateFontFace(ff.value)">
          <span [style.font-family]="ff.label">{{ ff.label }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="50">
      <mat-label>
        <smallstack-i18n data="@@sata.gundesigner.textfield.fontstyle"></smallstack-i18n>
      </mat-label>
      <mat-select [ngModel]="fontStyle">
        <mat-option *ngFor="let fs of fontStyles" [value]="fs.value" (click)="updateFontStyle(fs.value)">
          {{ fs.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="column" fxLayoutAlign="center stretch" *ngIf="isEditingNewText">
    <button mat-stroked-button [disabled]="!text" color="primar" (click)="addText()">
      <mat-icon color="primary" style="width: 24px; height: 16px; font-size: 16px">add</mat-icon>&nbsp;<smallstack-i18n
        data="@@sata.gundesigner.textfield.addtext"
      ></smallstack-i18n>
    </button>
  </div>
</div>
