export class Vector2 {
  public static zero: Vector2 = Object.freeze(new Vector2(0, 0));
  public static one: Vector2 = Object.freeze(new Vector2(1, 1));

  public static normalizedDegreePointer(degrees: number) {
    const radians = (degrees * Math.PI) / 180;
    return new Vector2(Math.sin(radians), Math.cos(radians));
  }
  public x: number;
  public y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  public length(): number {
    return this.distanceTo(Vector2.zero);
  }

  public distanceTo(target: Vector2): number {
    return this.distanceToXY(target.x, target.y);
  }

  public distanceToXY(x: number, y: number): number {
    const xDif: number = this.x - x;
    const yDif: number = this.y - y;
    return Math.sqrt(xDif * xDif + yDif * yDif);
  }

  public normalize(): Vector2 {
    const length = this.length();
    this.x /= length;
    this.y /= length;
    return this;
  }

  public round(): Vector2 {
    this.x = Math.round(this.x);
    this.y = Math.round(this.y);
    return this;
  }

  public floor(): Vector2 {
    this.x = Math.floor(this.x);
    this.y = Math.floor(this.y);
    return this;
  }

  public minus(other: Vector2): Vector2 {
    this.x -= other.x;
    this.y -= other.y;
    return this;
  }

  public plus(other: Vector2): Vector2 {
    this.x += other.x;
    this.y += other.y;
    return this;
  }

  public mul(scalar: number): Vector2 {
    this.x *= scalar;
    this.y *= scalar;
    return this;
  }

  public div(scalar: number): Vector2 {
    this.x /= scalar;
    this.y /= scalar;
    return this;
  }

  public angleInDegrees(): number {
    const a = (Math.atan2(-this.y, this.x) * 180) / Math.PI;
    if (a === -180) return 180;
    else return a;
  }

  public angle(): number {
    return Math.atan2(this.x, -this.y);
  }

  public cap(max: number): Vector2 {
    if (this.length() > max) {
      this.normalize();
      this.mul(max);
    }
    return this;
  }

  public angleBetween(v2: Vector2): number {
    const a1: number = this.angle();
    const a2: number = v2.angle();
    let a: number = a1 - a2;
    a += a > Math.PI ? -2 * Math.PI : a < -Math.PI ? 2 * Math.PI : 0;
    return a;
  }

  public angleBetweenInDegrees(v2: Vector2): number {
    const a1: number = this.angleInDegrees();
    const a2: number = v2.angleInDegrees();
    let a: number = Math.abs(a1 - a2);
    if (a > 180) a = 360 - a;
    return a;
  }

  public perpendicularLeft(): Vector2 {
    return new Vector2(-1 * this.y, this.x);
  }

  public isLeftOf(b: Vector2): boolean {
    const dot = this.x * -b.y + this.y * b.x;
    return dot > 0;
  }

  public isRightOf(b: Vector2): boolean {
    const dot = this.x * -b.y + this.y * b.x;
    return dot < 0;
  }

  public perpendicularRight(): Vector2 {
    return new Vector2(this.y, -1 * this.x);
  }

  public clone(): Vector2 {
    return new Vector2(this.x, this.y);
  }

  public equals(v: Vector2): boolean {
    return this.x === v.x && this.y === v.y;
  }

  public toString() {
    return `[${this.x}, ${this.y}]`;
  }

  public addAngle(b: number) {
    const a = -1 * this.angleInDegrees() + b;
    const l = this.length();
    const aR = (a / 360) * 2 * Math.PI;
    this.x = Math.cos(aR) * l;
    this.y = Math.sin(aR) * l;
  }
}
