export const MAX_IMAGES_PER_SIDE = 5;
export const MAX_IMAGE_SIZE: number = 20 * 1024 * 1024;

export const PRODUCT_CODES = {
  SATAJET_5000: "satajet5000",
  SATAJET_5500: "satajet5500"
};

export interface GunDesignerVariant {
  VM010: "Standard-keine" | "Digital-bar" | "Digital-psi";
  VM200: "chrom" | "schwarz";
  VM400: "mit Drehgelenk" | "ohne Drehgelenk";
  VM500: string;
}
