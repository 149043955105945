import { Injectable } from "@angular/core";
import { CommonService, MagentoStore } from "@sata/cdg-api";

@Injectable()
export class MagentoStoreService {
  private allMagentoStores: MagentoStore[];

  constructor(private commonService: CommonService) {}

  public gotoDefaultMagentoStorePage() {
    const defaultStore: MagentoStore = this.allMagentoStores.find((ms) => ms.isDefault);
    if (defaultStore) window.location.href = defaultStore.productPageUrl;
    else window.location.href = "https://sata.com";
  }

  public async load() {
    // setup magento stores
    this.allMagentoStores = await this.commonService.getMagentoStores().toPromise();
    // check store code
    const storeCode: string = new URLSearchParams(window.location.search).get("storeCode");
    if (!storeCode) this.gotoDefaultMagentoStorePage();
    else if (this.allMagentoStores.find((ms) => ms.storeCode === storeCode) === undefined)
      this.gotoDefaultMagentoStorePage();
  }
}
