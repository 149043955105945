<div class="images-and-text-container">
  <div *ngIf="store.isSmallScreen()" class="mobile-top-bar">
    <h1 class="headline">
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.design"></smallstack-i18n>
    </h1>
  </div>
  <div class="wizard-heading" [ngClass]="store.isSmallScreen() ? 'mobile-padding' : 'p-16'">
    <smallstack-i18n data="@@sata.gundesigner.wizard.design.imagesandtext"></smallstack-i18n>
  </div>
  <div class="button-bar" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="16px">
    <mat-tab-group
      #imageAndTextTabs
      style="flex: 8; overflow-y: auto"
      mat-stretch-tabs
      (selectedTabChange)="onTabChanged($event)"
    >
      <mat-tab>
        <div class="p-16">
          <ng-template mat-tab-label>
            <smallstack-i18n data="@@sata.gundesigner.wizard.images"></smallstack-i18n>
          </ng-template>
          <layers [store]="store"></layers>
          <image-library [store]="store"></image-library>
        </div>
      </mat-tab>
      <mat-tab>
        <div class="p-16">
          <ng-template mat-tab-label>
            <smallstack-i18n data="@@sata.gundesigner.wizard.textfields"></smallstack-i18n>
          </ng-template>
          <layers [store]="store"></layers>
          <text-detail *ngIf="isTextFieldEditorShown && !isInlineTextFieldEditorShown" [store]="store"></text-detail>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div *ngIf="store.isSmallScreen()" class="mobile-bottom-bar">
    <button mat-raised-button type="button" color="primary" (click)="close()">
      <smallstack-i18n data="@@sata.gundesigner.designer.common.close"></smallstack-i18n>
    </button>
  </div>
</div>
