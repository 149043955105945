import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { DomSanitizer, SafeValue } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { DesignStatusEvent } from "@sata/cdg-api";
import { GunCanvasConfig } from "./canvas/GunGame";
import { DesignStore } from "./DesignStore";
import { ImagesAndTextComponent } from "./images-and-text.component";

export interface Layer {
  id: string;
  movable: boolean;
  index: number;
  label: string;
  labelKey: string;
  visible: boolean;
  deletable: boolean;
  imageUrl: SafeValue;
  draggable: boolean;
  selected: boolean;
}
@Component({
  selector: "layers",
  templateUrl: "./layers.component.html",
  styleUrls: ["./layers.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LayersComponent implements OnInit, OnDestroy {
  public layers: Layer[] = [];

  public editable: boolean;

  public isInlineTextFieldEditorShown = false;
  public textId: string;

  @Input()
  public store: DesignStore;

  private subscr: Subscription;

  constructor(
    public domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private imagesAndTextComponent: ImagesAndTextComponent
  ) {}

  public ngOnDestroy(): void {
    if (this.subscr) {
      this.subscr.unsubscribe();
      delete this.subscr;
    }
  }
  public ngOnInit(): void {
    this.subscr = this.store.configChanges$.subscribe((cfg) => {
      this.editable = cfg && cfg.design && cfg.design.latestStatus.status === DesignStatusEvent.StatusEnum.Created;

      const t = cfg.design.sides[cfg.visibleSide].layers.find((l) => l.id === cfg.selectedLayerId);
      const nextTextId = t && t.text ? t.id : undefined;

      if (this.textId !== nextTextId) {
        this.textId = nextTextId;
        if (nextTextId) this.showInlineTextFieldEditor();
        else this.hideInlineTextFieldEditor();
      }

      this.updateLayers(cfg);
    });
  }

  public toggleVisible(l: Layer) {
    this.store.toggleImageVisibility(l.id);
  }

  public async deleteLayer(l: Layer) {
    await this.store.deleteLayer(l.id);
  }

  public async drop(evt) {
    const previousIndex: number = this.layers.length - evt.previousIndex - 1;
    const currentIndex: number = this.layers.length - evt.currentIndex - 1;
    await this.store.moveLayerToIndex(previousIndex, currentIndex);
  }

  public setSelectedLayer(l: Layer) {
    this.store.setSelectedLayer(l.id);
    if (l.imageUrl) this.imagesAndTextComponent.setTab(0);
    else this.imagesAndTextComponent.setTab(1);
  }

  public showInlineTextFieldEditor() {
    this.isInlineTextFieldEditorShown = true;
  }

  public hideInlineTextFieldEditor() {
    this.isInlineTextFieldEditorShown = false;
  }

  private updateLayers(cfg: GunCanvasConfig) {
    this.layers = cfg.design.sides[cfg.visibleSide].layers
      .map((lyr, i) => {
        const l: Layer = {
          movable: true,
          index: i,
          draggable: true,
          labelKey: null,
          label: lyr.image ? lyr.image.name : shorten(lyr.text.text, 30),
          visible: cfg.selectedLayerId === lyr.id || cfg.hiddenImageIds.indexOf(lyr.id) === -1,
          deletable: this.editable,
          imageUrl: lyr.image
            ? this.domSanitizer.bypassSecurityTrustStyle(`url('${lyr.image.previewUrl || lyr.image.url}')`)
            : undefined,
          id: lyr.id,
          selected: cfg.selectedLayerId === lyr.id
        };
        return l;
      })
      .reverse();
    this.cdr.detectChanges();
  }
}

function shorten(s: string, maxLength: number): string {
  if (s.length > maxLength - 3) return `${s.substr(0, maxLength - 3)}...`;
  else return s;
}
