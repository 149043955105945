<div class="price-box" style="flex: 1" data-test="cdg-pricebox">
  <!-- BASE PRODUCT -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2">
      <smallstack-i18n [data]="product?.title"></smallstack-i18n>
    </h2>
    <h2 class="mat-h2">
      <pim-product-price [product]="product" [priceZone]="priceZone"></pim-product-price>
    </h2>
  </div>

  <!-- PRODUCT OPTIONS -->
  <ng-container
    *ngTemplateOutlet="
      priceRow;
      context: { option: 'VM010', i18nKey: '@@sata.gundesigner.wizard.component.pressuredisplay' }
    "
  >
  </ng-container>
  <ng-container
    *ngTemplateOutlet="
      priceRow;
      context: { option: 'VM200', i18nKey: '@@sata.gundesigner.wizard.component.addoncolor' }
    "
  >
  </ng-container>

  <!-- TOTAL PRICE -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="mat-h1">
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.price"></smallstack-i18n>
      <span class="mat-caption" style="margin-left: 4px">
        <smallstack-i18n data="@@sata.gundesigner.wizard.component.price.net"></smallstack-i18n>
      </span>
    </h1>
    <h1 class="mat-h1">
      <pim-product-variant-price [product]="product" [productVariant]="productVariant" [priceZone]="priceZone">
      </pim-product-variant-price>
    </h1>
  </div>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <button mat-raised-button [disabled]="!editable" type="button" color="accent" (click)="submitDesign()">
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.ordernow"></smallstack-i18n>
    </button>
  </div>
</div>

<ng-template #priceRow let-option="option" let-i18nKey="i18nKey">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h4 class="mat-h4">
      <smallstack-i18n [data]="i18nKey"></smallstack-i18n>
    </h4>
    <h4 class="mat-h4">
      <pim-product-option-price
        [product]="product"
        [priceZone]="priceZone"
        [productVariant]="productVariant"
        [option]="option"
      ></pim-product-option-price>
    </h4>
  </div>
</ng-template>
