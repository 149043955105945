<div
  style="
    top: 106px;
    left: 16px;
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
  "
  *ngIf="!store.isSmallScreen()"
  fxLayout="column"
  fxLayoutGap="20px"
>
  <div *ngIf="laserung" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-slide-toggle [checked]="laserung.visible" (change)="toggleVisible(laserung)"></mat-slide-toggle>
    <smallstack-i18n [data]="laserung.labelKey"></smallstack-i18n>
  </div>
  <div *ngIf="anbauteile" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-slide-toggle [checked]="anbauteile.visible" (change)="toggleVisible(anbauteile)"></mat-slide-toggle>
    <smallstack-i18n [data]="anbauteile.labelKey"></smallstack-i18n>
  </div>
</div>
<div
  style="position: relative; width: 100%; height: 100%"
  [style.cursor]="cursorStyle"
  [style.background-color]="backgroundColor"
  (mousedown)="mouseDown($event)"
  (mouseup)="mouseUp()"
  (mouseleave)="mouseLeave()"
  (mouseenter)="mouseEnter($event)"
  (panstart)="mouseDown($event)"
  (panmove)="mouseMove($event)"
  (panend)="mouseUp()"
  (pinchstart)="onPinchStart($event)"
  (pinchend)="onPinchEnd($event)"
  (pinch)="onPinch($event)"
>
  <div *ngIf="highlight" [ngStyle]="highlight.style" style="z-index: 1; font-family: 'Trebuchet MS'">
    <div style="width: 100%; height: 100%; overflow: hidden; position: relative">
      <div
        fxLayout="row"
        fxLayoutAlign="end center"
        fxLayoutGap="20px"
        style="top: 20px; right: 20px; position: absolute"
      >
        <span>{{ highlight.topLeftText }}</span>
        <div *ngIf="showTextLegend">
          <img src="/assets/textfield_hint.png" height="16px" />&nbsp;
          <span class="mat-caption">{{ highlight.topRightText }}</span>
        </div>
      </div>
      <div style="bottom: 20px; left: 20px; position: absolute">{{ highlight.bottomLeftText }}</div>
      <div style="bottom: 20px; right: 20px; position: absolute"
        ><button mat-flat-button color="primary" (click)="wereDoneHere()">
          <smallstack-i18n data="@@sata.gundesigner.editdone.btn"></smallstack-i18n> </button
      ></div>
    </div>
  </div>
  <div style="position: absolute" [style.left]="offsetLeft" [style.top]="offsetTop" [style.transform]="scaleZoom">
    <div style="position: relative; pointer-events: none">
      <ng-container *ngFor="let l of layers">
        <img *ngIf="l.src" [src]="l.src" [ngStyle]="l.style" [style.transform]="l.transform" />
        <div *ngIf="l.text" [ngStyle]="l.style" [style.transform]="l.transform">
          <div style="position: relative; width: 100%; height: 100%">
            <div
              style="
                transform: translateX(-50%);
                position: absolute;
                left: 50%;
                text-align: center;
                white-space: nowrap;
              "
            >
              {{ l.text.text }}</div
            >
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
