<div class="configuration-container">
  <div *ngIf="store.isSmallScreen()" class="mobile-top-bar">
    <h1 class="headline">
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.settings"></smallstack-i18n>
    </h1>
  </div>
  <div>
    <!-- STEP 0: Modellauswahl -->
    <div class="wizard-heading">
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.gunmodel"></smallstack-i18n>
    </div>
    <mat-button-toggle-group [disabled]="!editable">
      <mat-button-toggle
        [checked]="productCode === 'satajet5000'"
        style="flex: 1"
        class="mt-12"
        (click)="changeGunModel('satajet5000')"
      >
        SATAjet 5000
      </mat-button-toggle>
      <mat-button-toggle
        [checked]="productCode === 'satajet5500'"
        style="flex: 1"
        class="mt-12"
        (click)="changeGunModel('satajet5500')"
      >
        SATAjet X 5500
      </mat-button-toggle>
    </mat-button-toggle-group>

    <!-- STEP 1: Standardausführung oder Digital -->

    <div class="wizard-heading">
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.pressuredisplay"></smallstack-i18n>
    </div>
    <!--<mat-radio-group [ngModel]="config.design.productVariant.options.VM010" (ngModelChange)="changeType($event)"
fxLayoutGap="40px">-->
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="productCode === 'satajet5000'">
      <mat-icon>check_circle</mat-icon>
      <mat-label
        ><smallstack-i18n data="@@sata.gundesigner.wizard.component.pressuredisplay.default"></smallstack-i18n
      ></mat-label>
    </div>
    <mat-button-toggle-group [disabled]="!editable" *ngIf="productCode !== 'satajet5000'">
      <mat-button-toggle
        [checked]="bodyType === 'Standard-keine'"
        style="flex: 1"
        value="Standard-keine"
        class="mt-12"
        (click)="changeBodyType('Standard-keine')"
      >
        <smallstack-i18n data="@@sata.gundesigner.wizard.component.pressuredisplay.default"></smallstack-i18n>
      </mat-button-toggle>
      <mat-button-toggle
        [checked]="bodyType === 'Digital-bar'"
        style="flex: 1"
        value="Digital-bar"
        class="mt-12"
        (click)="changeBodyType('Digital-bar')"
      >
        <smallstack-i18n data="@@sata.gundesigner.wizard.component.pressuredisplay.digitalbar"></smallstack-i18n>
      </mat-button-toggle>
      <mat-button-toggle
        [checked]="bodyType === 'Digital-psi'"
        style="flex: 1"
        value="Digital-psi"
        class="mt-12"
        (click)="changeBodyType('Digital-psi')"
      >
        <smallstack-i18n data="@@sata.gundesigner.wizard.component.pressuredisplay.digitalpsi"></smallstack-i18n>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <!-- </mat-radio-group>-->

    <!-- STEP 2: Düse -->

    <div class="wizard-heading">
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.technology"></smallstack-i18n> &amp;
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.size"></smallstack-i18n>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" class="technology-size">
      <mat-radio-group [ngModel]="nozzleType" fxLayoutGap="32px" [disabled]="!editable">
        <mat-radio-button [disabled]="!editable" (click)="updateNozzleType('HVLP')" value="HVLP" class="mt-12">
          HVLP
        </mat-radio-button>
        <mat-radio-button [disabled]="!editable" (click)="updateNozzleType('RP')" value="RP" class="mt-12">
          RP</mat-radio-button
        >
      </mat-radio-group>
      <mat-form-field>
        <mat-select [ngModel]="nozzleSize" [disabled]="!editable">
          <mat-option *ngFor="let ns of nozzleSizes" [value]="ns" (click)="updateNozzleSize(ns)">
            {{ ns }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <!-- STEP 4: Grundfarbe -->
  <div class="wizard-heading">
    <smallstack-i18n data="@@sata.gundesigner.wizard.component.backgroundcolor"></smallstack-i18n>
  </div>
  <div class="colorpicker-container" *ngIf="cpWidth">
    <span
      [cpToggle]="true"
      [cpWidth]="cpWidth"
      cpDialogDisplay="inline"
      cpAlphaChannel="disabled"
      [(colorPicker)]="color"
      class="color-picker"
      (colorPickerChange)="changeGroundColor($event)"
    ></span>
    <!-- <div style="width:2em; height:2em; padding: 4px;" *ngIf="!editable" [style.backgroundColor]="color">
        </div>-->
  </div>

  <!-- STEP 5: Farbe der Anbauteile -->
  <div>
    <div class="wizard-heading">
      <smallstack-i18n data="@@sata.gundesigner.wizard.component.addoncolor"></smallstack-i18n>
    </div>
    <div class="boxes" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="16px">
      <mat-card
        fxFlex="50"
        class="color-box black"
        (click)="changeKnobColor('schwarz')"
        [ngClass]="knobColor === 'schwarz' ? 'active' : 'inactive'"
      >
      </mat-card>
      <mat-card
        fxFlex="50"
        class="color-box chrome"
        (click)="changeKnobColor('chrom')"
        [ngClass]="knobColor === 'chrom' ? 'active' : 'inactive'"
      >
      </mat-card>
    </div>
  </div>

  <div *ngIf="store.isSmallScreen()" class="mobile-bottom-bar">
    <button mat-raised-button type="button" color="primary" (click)="close()">
      <smallstack-i18n data="@@sata.gundesigner.designer.common.close"></smallstack-i18n>
    </button>
  </div>
</div>
