import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { GunCanvasConfig } from "./canvas/GunGame";
import { DesignStore } from "./DesignStore";

@Component({
  selector: "zoom-component",
  templateUrl: "./zoom.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomComponent implements OnDestroy, OnInit {
  @Input()
  public store: DesignStore;
  public zoom = 1;
  public maxZoom = 2.5;
  public minZoom = 1;
  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.subscription = this.store.configChanges$.subscribe((cfg: GunCanvasConfig) => {
      this.minZoom = cfg.minZoom;
      this.maxZoom = cfg.maxZoom;
      this.zoom = cfg.zoom;
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }

  public zoomChanged(newZoom: number) {
    this.store.changeZoom(newZoom);
  }

  public resetZoom() {
    this.store.resetZoom();
  }

  public incZoom() {
    this.store.changeZoom(this.zoom + (this.maxZoom - this.minZoom) / 10);
  }

  public decZoom() {
    this.store.changeZoom(this.zoom - (this.maxZoom - this.minZoom) / 10);
  }
}
