<div style="position: relative; width: 100%; height: 600px; overflow: hidden; z-index: 100000">
  <img [src]="pistoleUrl" style="width: 100%; height: 100%; position: absolute; top: 0px; left: 0px" />
  <div class="design-overlay" style="position: absolute; left: 50%; top: 50%">
    <div style="position: relative; pointer-events: none">
      <div
        style="width: 2000px; height: 2000px; transform: translate(-50%, -50%)"
        [style.background]="backgroundColor"
      ></div>
      <!-- <img *ngFor="let i of images" [src]="i.src" [ngStyle]="i.style" [style.transform]="i.transform" /> -->
      <ng-container *ngFor="let l of layers">
        <img *ngIf="l.src" [src]="l.src" [ngStyle]="l.style" [style.transform]="l.transform" />
        <div *ngIf="l.text" [ngStyle]="l.style" [style.transform]="l.transform">
          <div style="position: relative; width: 100%; height: 100%">
            <div
              style="
                transform: translateX(-50%);
                position: absolute;
                left: 50%;
                text-align: center;
                white-space: nowrap;
              "
            >
              {{ l.text.text }}</div
            >
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <img [src]="backgroundUrl" style="width: 100%; height: 100%; position: absolute; top: 0px; left: 0px" />
  <img [src]="laserungUrl" style="width: 100%; height: 100%; position: absolute; top: 0px; left: 0px" />
  <img [src]="addOnUrl" style="width: 100%; height: 100%; position: absolute; top: 0px; left: 0px" />
</div>

<div *ngIf="isIncompatibleBrowser()" fxLayout="row" fxLayoutAlign="center center">
  <smallstack-i18n data="@@sata.gundesigner.incompatiblebrowser.preview.message" class="mat-caption"></smallstack-i18n>
</div>

<div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 20px">
  <div class="side-switch">
    <img
      width="80"
      height="80"
      [style.background-color]="visibleSide === 'left' ? '#eaeaea' : ''"
      [src]="getImageUrl('assets/hud-assets/left-gun-button.png')"
      (click)="setVisibleSide('left')"
      style="cursor: pointer; margin-right: 10px"
    />
    <img
      width="80"
      height="80"
      [style.background-color]="visibleSide === 'right' ? '#eaeaea' : ''"
      [src]="getImageUrl('assets/hud-assets/right-gun-button.png')"
      (click)="setVisibleSide('right')"
      style="cursor: pointer"
    />
  </div>
</div>
<div mat-dialog-actions align="end" style="padding: 0px">
  <button mat-flat-button color="primary" (click)="closePreview()">
    <smallstack-i18n data="@@sata.gundesigner.designer.common.close"></smallstack-i18n>
  </button>
</div>
