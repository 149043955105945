import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DesignText } from "@sata/cdg-api";
import { TranslationStore } from "@smallstack/i18n-components";
import { Subscription } from "rxjs";
import { DesignStore } from "./DesignStore";
import { ImagesAndTextComponent } from "./images-and-text.component";

@Component({
  selector: "text-detail",
  templateUrl: "./text-detail.component.html",
  styleUrls: ["./text-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextDetailComponent implements OnInit, OnDestroy {
  @Input()
  public store: DesignStore;

  // @Autowired()
  // private notificationService: NotificationService;

  public text = "";

  public isEditingNewText: boolean;
  public editStoredText: boolean;

  public fontFace: DesignText.FontFaceEnum = DesignText.FontFaceEnum.Roboto;
  public fontStyle: DesignText.FontStyleEnum = DesignText.FontStyleEnum.Regular;

  public color = "#fff000";

  public fontFaces: Array<{ label: string; value: string }> = Object.keys(DesignText.FontFaceEnum).map((ffk) => {
    return {
      label: ffk.split("_").join(" "),
      value: ffk
    };
  });

  public fontStyles: Array<{ label: string; value: string }> = Object.keys(DesignText.FontStyleEnum).map((fsk) => {
    const key = `sata.gundesigner.textdetail.component.fontstyle.${fsk.toLowerCase()}`;
    const translation = this.translationStore.translateByKey(key);
    return {
      label: translation,
      value: fsk
    };
  });

  private subscription: Subscription;

  private textId: string;

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public designStore: DesignStore,
    private imagesAndTextComponent: ImagesAndTextComponent,
    private translationStore: TranslationStore
  ) {
    this.store = designStore;
  }

  public async updateFontFace(ff: DesignText.FontFaceEnum) {
    this.fontFace = ff;
    if (!this.isEditingNewText) await this.store.updateTextFont(this.textId, { fontFace: ff });
  }

  public async updateFontStyle(fs: DesignText.FontStyleEnum) {
    this.fontStyle = fs;
    if (!this.isEditingNewText) await this.store.updateTextFont(this.textId, { fontStyle: fs });
  }

  public async textChanged() {
    await this.store.updateTextFont(this.textId, { text: this.text });
  }

  public changeColor(value: string) {
    this.color = value;
  }

  public async saveColor(value: string) {
    this.color = value;
    if (!this.isEditingNewText) await this.store.updateTextFont(this.textId, { color: value });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }

  public ngOnInit(): void {
    this.subscription = this.store.configChanges$.subscribe((cfg) => {
      const t = cfg.design.sides[cfg.visibleSide].layers.find((l) => l.id === cfg.selectedLayerId);
      this.textId = t && t.text ? t.id : undefined;
      if (this.textId) {
        this.fontFace = t.text.fontFace;
        this.fontStyle = t.text.fontStyle;
        this.color = t.text.color;
        this.text = t.text.text;
        this.isEditingNewText = false;
      } else {
        this.isEditingNewText = true;
      }
      this.cdr.detectChanges();
    });
  }

  public async addText() {
    if (this.isEditingNewText && this.text) {
      await this.store.addText(this.text, this.fontFace as any, this.fontStyle as any, this.color);
    }
  }

  public hideTextFieldEditor() {
    this.imagesAndTextComponent.hideTextFieldEditor();
  }
}
