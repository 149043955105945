import { Design } from "@sata/cdg-api";

export interface GunCanvasConfig {
  cameraOffset: { x: number; y: number };
  design: Design;
  configuration: any;
  addOnsVisible: boolean;
  showAnbauteile: boolean;
  showLasering: boolean;
  screenTooSmall: boolean;
  editable: boolean;
  visibleSide: "right" | "left";
  loadingDesignStatus: "loading" | "not-found" | "load-error" | "ok" | "network-error";
  networkActivity: string;
  networkProgress: number;
  hiddenImageIds: string[];
  selectedLayerId?: string;
  zoom: number;
  editingMode: GunCanvasImageEditingMode;
  minZoom: number;
  maxZoom: number;
  offline: boolean;
}

export interface GunCanvasHighlightSquareConfig {
  center: {
    x: number;
    y: number;
  };
  width: number;
  height: number;
  angle: number;
}

export enum GunCanvasImageEditingMode {
  move = "move",
  scale = "scale",
  rotate = "rotate",
  none = "none"
}
